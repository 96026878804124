import React, { useState } from 'react';

const NoCopyPasteMessage = () => {
  // State to show/hide the modal
  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle the modal visibility
  const handleClick = () => {
    setShowPopup(true);
  };

  const closeModal = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {/* The clickable text */}
      <p
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          padding: '10px',
          color: 'white',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          textAlign: 'center',
          borderRadius: '5px',
          userSelect: 'none',
        }}
      >
        No Copy-Paste Allowed
      </p>

      {/* Simple Modal */}
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '400px',
              textAlign: 'center',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>I warned you—no copying and pasting allowed!</h2>
            <button onClick={closeModal} style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: '#f00', color: 'white', border: 'none', borderRadius: '5px' }}>
            Close 🐧
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoCopyPasteMessage;
