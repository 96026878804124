import React, { useState } from 'react';

const BuyButtons = () => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    position: 'relative', 
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 1,
  };

  const tooltipStyle = {
    position: 'absolute',
    bottom: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '8px 12px',
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    opacity: hoveredButton ? 1 : 0,
    transition: 'opacity 0.2s ease',
    zIndex: 10,
  };

  const meteoraButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#1E1E1E',
    color: '#ffffff',
  };

  const raydiumButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#3CBBF6',
    color: '#000000',
  };

  const orcaButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#1E1E1E',
    color: '#ffffff',
  };

  const jupiterButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#3CBBF6',
    color: '#ffffff',
  };


  const coininButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#1E1E1E',
    color: '#ffffff',
  };

  const handleHover = (e, scale, buttonName) => {
    e.currentTarget.style.transform = `scale(${scale})`;
    e.currentTarget.style.boxShadow =
      scale > 1
        ? '0px 6px 8px rgba(0, 0, 0, 0.15)'
        : '0px 4px 6px rgba(0, 0, 0, 0.1)';
    setHoveredButton(scale > 1 ? buttonName : null);
  };

  return (
    <div style={containerStyle}>
      {/* Tooltip */}
      {hoveredButton && <div style={tooltipStyle}>{hoveredButton}</div>}

{/* Meteora Button */}
<a
        href="https://app.meteora.ag/pools/5NcCN8X7JteQ9VsztDPqop5ZdJSQeyJNuxg9VaUBsACK"
        target="_blank"
        rel="noopener noreferrer"
        style={meteoraButtonStyle}
        onMouseEnter={(e) => handleHover(e, 1.1, 'METEORA')}
        onMouseLeave={(e) => handleHover(e, 1, null)}
      >
        <img
          src="assets/images/meteora.png"
          alt="ORCA Logo"
          style={{ width: '40px', height: '40px', marginRight: '0px' }}
        />
      </a>

      {/* Raydium Button */}
      <a
        href="https://raydium.io/swap/?inputMint=sol&outputMint=2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1"
        target="_blank"
        rel="noopener noreferrer"
        style={raydiumButtonStyle}
        onMouseEnter={(e) => handleHover(e, 1.1, 'RAYDIUM')}
        onMouseLeave={(e) => handleHover(e, 1, null)}
      >
        <img
          src="assets/images/Raydium.png"
          alt="Raydium Logo"
          style={{ width: '40px', height: '40px', marginRight: '0px' }}
        />
      </a>

      {/* ORCA Button */}
      <a
        href="https://www.orca.so/?tokenIn=So11111111111111111111111111111111111111112&tokenOut=2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1"
        target="_blank"
        rel="noopener noreferrer"
        style={orcaButtonStyle}
        onMouseEnter={(e) => handleHover(e, 1.1, 'ORCA')}
        onMouseLeave={(e) => handleHover(e, 1, null)}
      >
        <img
          src="assets/images/Orca.png"
          alt="ORCA Logo"
          style={{ width: '40px', height: '40px', marginRight: '0px' }}
        />
      </a>

      {/* Jupiter Button */}
      <a
        href="https://jup.ag/swap/SOL-2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1"
        target="_blank"
        rel="noopener noreferrer"
        style={jupiterButtonStyle}
        onMouseEnter={(e) => handleHover(e, 1.1, 'JUPITER')}
        onMouseLeave={(e) => handleHover(e, 1, null)}
      >
        <img
          src="assets/images/Jupiter.png"
          alt="Jupiter Logo"
          style={{ width: '40px', height: '40px', marginRight: '0px' }}
        />
      </a>
      {/* CoinInn Button */}
      <a
        href="https://www.coininn.com/coin/TPG"
        target="_blank"
        rel="noopener noreferrer"
        style={coininButtonStyle}
        onMouseEnter={(e) => handleHover(e, 1.1, 'CoinIn')}
        onMouseLeave={(e) => handleHover(e, 1, null)}
      >
        <img
          src="assets/images/coinin.png"
          alt="ORCA Logo"
          style={{ width: '40px', height: '40px', marginRight: '0px' }}
        />
      </a>
    </div>
  );
};

export default BuyButtons;
