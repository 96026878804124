// src/App.js
import React from 'react';
/* import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'; */
import BuyButtons from './BuyButtons';
import LiquidityFarmInfo from './LiquidityFarmInfo';
import './App.css';
import './CustomInputField.css';
import HomeSection from './HomeSection.js';
/* import WesternDuelGame from './WesternDuelGame';
 */import CryptoLinks from './CryptoLinks.js';
import Aggregators from './Aggregators.js';
import NoCopyPasteMessage from './NoCopyPasteMessage';


function App() {

    const completedSteps = [true, true, false, false, false, false, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,];

    function showPopup(event, linkType) {
        // Prevent default action of the link
        event.preventDefault();

        // Define funny messages based on the link clicked
        const messages = {
            'Privacy Policy': "Don't worry, your secrets are safe with us... probably.",
            'Terms & Conditions': "You read the terms, right? No? It's cool, we did it for you.",
        };

        // Show an alert or a custom popup with a funny message
        alert(messages[linkType] || "Oops! Something went wrong.");
    }

    const showSarcasticMessage = () => {
        alert("Oh, you think this will work? What a shame.");
    };

    return (
        <>
            <div className="App">
                <div className="v_dark" data-spy="scroll" data-offset="110">
                    <div id="loader-wrapper">
                        <div id="loading-center-absolute">
                            <div className="object" id="object_four"></div>
                            <div className="object" id="object_three"></div>
                            <div className="object" id="object_two"></div>
                            <div className="object" id="object_one"></div>
                        </div>
                        <div className="loader-section section-left"></div>
                        <div className="loader-section section-right"></div>
                    </div>
                    <header className="header_wrap fixed-top">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand page-scroll animation" href="#buy" data-animation="fadeInDown" data-animation-delay="1s">
                                    <img className="logo_light" src="assets/images/logo.png" alt="logo" />
                                    <img className="logo_dark" src="assets/images/logo_dark.png" alt="logo" />
                                </a>
                                <button className="navbar-toggler animation" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data-animation="fadeInDown" data-animation-delay="1.1s">
                                    <span className="ion-android-menu"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav m-auto">
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.1s"><a className="nav-link page-scroll nav_item" href="#buy">Buy</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.2s"><a className="nav-link page-scroll nav_item" href="#roadmap">Roadmap</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.3s"><a className="nav-link page-scroll nav_item" href="#about">About</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.4s"><a className="nav-link page-scroll nav_item" href="#token">Token</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.6s"><a className="nav-link page-scroll nav_item" href="#team">Team</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.7s"><a className="nav-link page-scroll nav_item" href="#faq">FAQ</a></li>
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.8s"><a className="nav-link page-scroll nav_item" href="#contact">Contact</a></li>
                                    </ul>
                                    <ul className="navbar-nav nav_btn align-items-center">
                                        <li className="animation" data-animation="fadeInDown" data-animation-delay="1.9s">
                                            <div className="lng_dropdown">
                                                <select name="countries" id="lng_select">
                                                    <option value='us' data-image="assets/images/us.png" data-title="United States">US</option>
                                                    <option value='us' data-image="assets/images/us.png" data-title="United States">US</option>
                                                    <option value='us' data-image="assets/images/us.png" data-title="United States">US</option>
                                                    <option value='us' data-image="assets/images/us.png" data-title="United States">US</option>
                                                    <option value='us' data-image="assets/images/us.png" data-title="United States">US</option>
                                                </select>
                                            </div>
                                        </li>
                                        {/* <li className="animation" data-animation="fadeInDown" data-animation-delay="2s"><WalletMultiButton /></li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>
                    <section>
                        <HomeSection />
                        <div style={{ textAlign: 'center', paddingTop: '20px' }} id="buy">
                            <h1 style={{
                                fontSize: '36px',
                                fontWeight: 'bold',
                                margin: 0,
                                background: 'linear-gradient(90deg, #3CBBF6, #1E1E1E)',
                                WebkitBackgroundClip: 'text',
                                fontFamily: "MedievalSharp, sans-serif",
                            }}>
                                BUY $TPG NOW
                            </h1>
                            <p style={{
                                fontSize: '16px',
                                color: '#666',
                            }}>
                                Choose your platform below to start buying.
                            </p>
                            <BuyButtons />
                        </div>
                    </section>
                    <div className="container" style={{ textAlign: "center" }}>
                        <h2 className="text-white" style={{
                            fontFamily: "MedievalSharp, sans-serif", fontSize: "30px", fontWeight: "bold",
                        }}>
                            Follow the Feathers
                        </h2>
                        <Aggregators />
                        <CryptoLinks />
                    </div>
                    <div style={{ textAlign: 'center', paddingBottom: '20px', paddingTop: '20px' }}>
                        <LiquidityFarmInfo />
                    </div>
                    <div className="iframe-container">
                        <iframe
                            id="dextools-widget"
                            title="DEXTools Trading Chart"
                            src="https://dexscreener.com/solana/6v8RPeJQHYiT96HVTYynD8GhWrKLuz63U5jSZm2SrTv1?embed=1&loadChartSettings=0&trades=0&tabs=0&chartLeftToolbar=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=1&chartType=usd&interval=3"
                            className="dextools-iframe"
                        ></iframe>
                    </div>
                    <br />
                    {/*                     <div className="iframe-container">
                        <iframe
                            id="dextools-widget"
                            title="DEXTools Trading Chart"
                            src="https://www.dextools.io/widget-chart/en/solana/pe-light/6v8RPeJQHYiT96HVTYynD8GhWrKLuz63U5jSZm2SrTv1?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false"
                            className="dextools-iframe"
                        ></iframe>
                    </div> */}
                    <section id="roadmap" className="small_pb small_pt" style={{ paddingTop: '20px' }}>
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">ROADMAP</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Because We Like Knowing Where We’re Going.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                        <img src="assets/images/service_icon1.png" alt="service_icon1" />
                                        <h4>PHASE 1</h4> {/* Place <h4> outside of <p> */}
                                        <div> {/* Use <div> instead of <p> to wrap the list */}
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                Cheat Death Twice {completedSteps[0] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                Winning Elections {completedSteps[1] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Seize the White House {completedSteps[2] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Enshroud the Iceberg Lounge {completedSteps[3] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Confine Joe Biden in Arkham {completedSteps[4] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                375 $SOL is the phase one goal {completedSteps[5] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <h4 className="text-center animation">TRUMPENGUINOMICS</h4> {/* This <h4> tag is fine here */}
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                List on
                                                <a href="https://raydium.io/swap/?inputMint=sol&outputMint=2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1" target="_blank" rel="noopener noreferrer"> RAYDIUM </a>
                                                {completedSteps[6] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                List on
                                                <a href="https://www.dextools.io/app/en/token/trumpenguin" target="_blank" rel="noopener noreferrer"> DEXTools </a>
                                                {completedSteps[6] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                List on
                                                <a href="https://dexscreener.com/solana/6v8rpejqhyit96hvtyynd8ghwrkluz63u5jszm2srtv1" target="_blank" rel="noopener noreferrer"> DEX SCREENER </a>
                                                {completedSteps[6] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px", color: "white" }}> 🐧
                                                List on
                                                <a href="https://app.meteora.ag/pools/5NcCN8X7JteQ9VsztDPqop5ZdJSQeyJNuxg9VaUBsACK" target="_blank" rel="noopener noreferrer"> Meteora </a>
                                                {completedSteps[7] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG tokens {completedSteps[8] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to Goonz {completedSteps[9] && <span>✓ </span>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                        <img src="assets/images/service_icon2.png" alt="service_icon2" />
                                        <h4>PHASE 2</h4> {/* Keep the <h4> outside of the <p> tag */}
                                        <div> {/* Wrap the list in a <div> instead of <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Make TRUTH #1 Social Platform {completedSteps[10] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Avenge Against FOES {completedSteps[11] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Destroy Diversity & Protect the Family {completedSteps[12] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Sinister Union of Criminal Families {completedSteps[13] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Raising 2500 $SOL {completedSteps[9] && <span>✓ </span>}
                                            </span>
                                            <h4 className="text-center animation">TRUMPENGUINOMICS</h4> {/* <h4> is now outside of <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Burn $TPG for completed tasks {completedSteps[14] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to Lieutenants {completedSteps[15] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to KOLs {completedSteps[16] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                List on Jupiter {completedSteps[17] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                List on ORCA {completedSteps[18] && <span>✓ </span>}
                                            </span><br /><br /><br />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                                        <img src="assets/images/service_icon3.png" alt="service_icon3" />
                                        <h4>PHASE 3</h4> {/* <h4> is placed outside the <p> */}
                                        <div> {/* Wrap the list items in a <div> instead of <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Get 200,000 BTC´s with FOES Money {completedSteps[19] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Fuel the growth of US businesses {completedSteps[20] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Reform housing to make it affordable {completedSteps[21] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Revolutionize health care {completedSteps[22] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Raising 5000 $SOL {completedSteps[23] && <span>✓ </span>}
                                            </span>
                                            <h4 className="text-center animation">TRUMPENGUINOMICS</h4> {/* <h4> is outside the <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to HODLERS {completedSteps[24] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Keep burning $TPG {completedSteps[25] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to Cosa Nostra {completedSteps[25] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                List on mid-size DEX {completedSteps[27] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Time to get listed on CEX {completedSteps[28] && <span>✓ </span>}
                                            </span><br /><br /><br />
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                                        <img src="assets/images/service_icon4.png" alt="service_icon4" />
                                        <h4>PHASE 4</h4> {/* <h4> is placed outside the <p> */}
                                        <div> {/* Wrap the list items in a <div> instead of <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Purge Narcoterrorists to MAGA {completedSteps[29] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Employment McDonalds opportunities {completedSteps[30] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Remove Mining Operations Taxes {completedSteps[31] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Impose Deserters Tariffs (John Deer) {completedSteps[32] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Raising 10000 $SOL {completedSteps[33] && <span>✓ </span>}
                                            </span>
                                            <h4 className="text-center animation">TRUMPENGUINOMICS</h4> {/* <h4> is outside the <p> */}
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to VIP HODLERS {completedSteps[34] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Continue burning $TPG {completedSteps[35] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                DROP $TPG to Business {completedSteps[36] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                List us on the top 10 CEX {completedSteps[37] && <span>✓ </span>}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: "16px" }}> 🐧
                                                Going for the top 10 DEX {completedSteps[38] && <span>✓ </span>}
                                            </span><br /><br /><br />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="about" className="small_pt">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="text_md_center">
                                        <img className="animation" data-animation="zoomIn" data-animation-delay="0.2s" src="assets/images/TRUMPENGUIN_PFP_NBG.png" alt="aboutimg2" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 res_md_mt_30 res_sm_mt_20">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">WHAT IS TRUMPENGUIN?</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">💸 "Another day, another meme token!" 🐧 Trumpenguin is here to satisfy the hunger of every crypto community waiting to throw their hard-earned cash into the next "unicorn" coin. 🦄 Will it moon? Probably. Will it tank? Maybe. But who cares? The memes are fire, and that's all that matters. 🚀 So grab your popcorn, folks, and watch as Trumpenguin takes you on a rollercoaster of hype, laughs, and questionable decisions.</p>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.8s">🦅 Trumpenguin isn’t just a token – it’s your front-row seat to the wild ride of Trump’s next presidential era! 🎩 Hold onto your wallets as we track every tweet, decision, and “yuge” move he makes in the years to come. 📈 Will Trumpenguin soar like his policies or crash and burn like some of his tweets? Only time will tell, but one thing’s for sure – this token is here to follow the drama, the decisions, and the chaos, one meme at a time! 😂💥 Oh, and here's the twist: every time Trumpenguin completes a task on the roadmap, tokens will be burned, making this journey even more... volatile. 🔥 Hold on tight as we burn through those tokens faster than a Trump rally speech! 🏃‍♂️💨 </p>
                                    </div>
                                    <a href="#buy" className="btn btn-default btn-radius video animation" data-animation="fadeInUp" data-animation-delay="1s">Buy Now or Cry Later!<i className="ion-ios-arrow-thin-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="token" className="section_token token_sale bg_light_dark" data-z-index="1" data-parallax="scroll" data-image-src="assets/images/token_bg.png">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">TRUMPENGUINOMICS</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Join the Pros, Predict the Future. Tokens Accepted! </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 res_md_mb_40">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Tokens Up for Grabs!</h4>
                                    </div>
                                    <div className="lg_pt_20 res_sm_pt_0 text-center animation" data-animation="fadeInRight" data-animation-delay="0.2s">
                                        <img src="assets/images/trumpenguinomics_nobg.png" alt="trumpenguinomics" />
                                    </div>
                                    <div className="divider small_divider"></div>
                                    <ul className="list_none list_chart text-center">
                                        <li>
                                            <span className="chart_bx color6"></span>
                                            <span>Drop</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color0"></span>
                                            <span>Goons Presale</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color8"></span>
                                            <span>KOLs Extortion</span>
                                        </li>
                                        <br></br>
                                        <li>
                                            <span className="chart_bx color5"></span>
                                            <span>Lieutenants Presale</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color11"></span>
                                            <span>Cosa Nostra</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color5"></span>
                                            <span>Business Mgmt</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color5"></span>
                                            <span>DEX/CEX</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Distribution in Progress!</h4>
                                    </div>
                                    <div className="lg_pt_20 res_sm_pt_0 text-center animation" data-animation="fadeInLeft" data-animation-delay="0.2s">
                                        <img src="assets/images/distribution9.png" alt="distribution9" />
                                    </div>
                                    <div className="divider small_divider"></div>
                                    {/*  <ul className="list_none list_chart text-center">
                                        <li>
                                            <span className="chart_bx color1"></span>
                                            <span>ICO Sale</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color4"></span>
                                            <span>Build Out</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color2"></span>
                                            <span>Team & Advisers</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color5"></span>
                                            <span>Private Investors</span>
                                        </li>
                                        <li>
                                            <span className="chart_bx color3"></span>
                                            <span>Bounty</span>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id="mobileapp" className="bg_light_dark" data-z-index="1" data-parallax="scroll" data-image-src="assets/images/app_bg.png">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12 col-sm-12">
                                    <div className="title_default_light title_border text_md_center text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Why a Meme Token?</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Meme tokens have become the lifeblood of the crypto community – they're fun, engaging, and give investors a chance to connect with pop culture. By creating a meme token, we’re tapping into the viral nature of internet humor and the hype that fuels crypto communities. Plus, let’s be honest – who doesn’t love a little lighthearted speculation and the thrill of riding the meme wave to the moon? 🚀 </p>
                                    </div>
                                    <div className="title_default_light title_border text_md_center text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Why Donald Trump?</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Donald Trump is a larger-than-life figure, and his bold, unpredictable decisions make him a perfect match for the world of meme tokens. From his time in office to his influence on current events, Trump keeps everyone talking – whether you love him or hate him, you can't ignore him. By aligning with Trump’s brand, we’re tapping into a huge global audience, all while adding a satirical spin that keeps things fresh, funny, and full of energy. 🦅💥 </p>
                                    </div>
                                    <div className="title_default_light title_border text_md_center text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Why Solana?</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">We chose Solana for its blazing-fast transaction speeds and low fees, making it the perfect blockchain to support a meme token that’s all about hype and fast action. Solana’s scalability ensures that as the Trumpenguin community grows, our token can keep up without getting bogged down by slow transaction times. Plus, with Solana’s growing ecosystem, we can build a solid foundation for future growth and make sure our memes get to you quicker than a Trump tweet. ⚡️🔥</p>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                            • The Solana chain's speed and scalability would allow for real-time monitoring and flagging of suspicious activity.<br></br>
                                            • ⁠The Solana chain's security would ensure the integrity of the gaming environment and protect player identities.<br></br>
                                            • ⁠The Solana chain's decentralized nature aligns with Arian Ai's trust token based system that guarantees the integrity of the gaming environment. <br></br>
                                            • ⁠The Solana chain's strong community would provide support and resources for Arian Ai.<br></br>
                                            • ⁠The Solana chain's compliance with regulations and laws in many jurisdictions could attract more users and investors to Arian Ai.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <div className="res_md_mt_50 res_sm_mt_30 text-center animation" data-animation="fadeInRight" data-animation-delay="0.2s">
                                        <img src="assets/images/mobile_app3.jpeg" alt="mobile_app3" />
                                        <img src="assets/images/mobile_app4.jpeg" alt="mobile_app3" />
                                        <img src="assets/images/mobile_app5.jpeg" alt="mobile_app3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section id="team" className="section_team small_pt">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 offset-lg-2">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">The Minds Leading the Charge!</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">The team is full of stars, and he shines brightly! Motivated, driven, and always willing to pitch in, no matter the task! </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row small_space">
                                <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
                                    <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                        <div className="text-center">
                                            <img src="assets/images/Donald_Trump.png" alt="placeholder9" />
                                            <div className="team_social_s2 list_none">
                                                <ul>
                                                    <a href="https://x.com/realDonaldTrump" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team_info text-center">
                                            <h4><a href="#team1" className="content-popup">Donald Trumpenguin</a></h4>
                                            <p>Mr. President</p>
                                        </div>
                                        <div id="team1" className="team_pop mfp-hide">
                                            <div className="row m-0">
                                                <div className="col-md-4 text-center">
                                                    <div className="team_img_wrap">
                                                        <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                        <div className="team_title">
                                                            <h4>Donald Trumpenguin</h4>
                                                            <span>Mr. President</span>
                                                        </div>
                                                    </div>
                                                    <div className="social_single_team list_none mt-3">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="pt-3">
                                                        <h5>About</h5>
                                                        <hr />
                                                        <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
                                    <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                        <div className="text-center">
                                            <img src="assets/images/elon_musk.png" alt="elon_musk" />
                                            <div className="team_social_s2 list_none">
                                                <ul>
                                                    <a href="https://x.com/elonmusk" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team_info text-center">
                                            <h4><a href="#team2" className="content-popup">Elon Muspenguin</a></h4>
                                            <p>Cost-Cutting Specialist</p>
                                        </div>
                                        <div id="team2" className="team_pop mfp-hide">
                                            <div className="row m-0">
                                                <div className="col-md-4 text-center">
                                                    <div className="team_img_wrap">
                                                        <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                        <div className="team_title">
                                                            <h4>Elon Muspenguin</h4>
                                                            <span>Cost-Cutting Specialist</span>
                                                        </div>
                                                    </div>
                                                    <div className="social_single_team list_none mt-3">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="pt-3">
                                                        <h5>About</h5>
                                                        <hr />
                                                        <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
                                    <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                        <div className="text-center">
                                            <img src="assets/images/kamala_harris.png" alt="kamala_harris" />
                                            <div className="team_social_s2 list_none">
                                                <ul>
                                                    <a href="https://x.com/KamalaHarris" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team_info text-center">
                                            <h4><a href="#team3" className="content-popup">Kamala Harpenguin</a></h4>
                                            <p>Trumpenguin Secretary</p>
                                        </div>
                                        <div id="team3" className="team_pop mfp-hide">
                                            <div className="row m-0">
                                                <div className="col-md-4 text-center">
                                                    <div className="team_img_wrap">
                                                        <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                        <div className="team_title">
                                                            <h4>Kamala Harpenguin</h4>
                                                            <span>Trumpenguin Secretary</span>
                                                        </div>
                                                    </div>
                                                    <div className="social_single_team list_none mt-3">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="pt-3">
                                                        <h5>About</h5>
                                                        <hr />
                                                        <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="1s">
                                        <div className="text-center">
                                            <img src="assets/images/Donald_Trump_Jr.png" alt="Donald_Trump_Jr" />
                                            <div className="team_social_s2 list_none">
                                                <ul>
                                                    <a href="https://x.com/DonaldJTrumpJr" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team_info text-center">
                                            <h4><a href="#team4" className="content-popup">Donald Trumpenguin Jr</a></h4>
                                            <p>Advisory and Vetting Role</p>
                                        </div>
                                        <div id="team4" className="team_pop mfp-hide">
                                            <div className="row m-0">
                                                <div className="col-md-4 text-center">
                                                    <div className="team_img_wrap">
                                                        <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                        <div className="team_title">
                                                            <h4>Donald Trumpenguin Jr</h4>
                                                            <span>Advisory and Vetting Role</span>
                                                        </div>
                                                    </div>
                                                    <div className="social_single_team list_none mt-3">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="pt-3">
                                                        <h5>About</h5>
                                                        <hr />
                                                        <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider large_divider"></div>
                            {/*     <div className="row">
                                <div className="col-md-12">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Invester Board</h4>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row small_space justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
                                            <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                                <div className="text-center">
                                                    <img src="assets/images/dev.png" alt="placeholder9" />
                                                    <div className="team_social_s2 list_none">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="team_info text-center">
                                                    <h4><a href="#team5" className="content-popup">Tricia Diyana</a></h4>
                                                    <p>Invester</p>
                                                </div>
                                                <div id="team5" className="team_pop mfp-hide">
                                                    <div className="row m-0">
                                                        <div className="col-md-4 text-center">
                                                            <div className="team_img_wrap">
                                                                <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                                <div className="team_title">
                                                                    <h4>Tricia Diyana</h4>
                                                                    <span>Invester</span>
                                                                </div>
                                                            </div>
                                                            <div className="social_single_team list_none mt-3">
                                                                <ul>
                                                                    <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-3">
                                                                <h5>About</h5>
                                                                <hr />
                                                                <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 res_md_mb_30 res_sm_mb_20">
                                            <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                <div className="text-center">
                                                    <img src="assets/images/dev.png" alt="placeholder9" />
                                                    <div className="team_social_s2 list_none">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-linkedin"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="team_info text-center">
                                                    <h4><a href="#team6" className="content-popup">Kent Pierce</a></h4>
                                                    <p>Invester</p>
                                                </div>
                                                <div id="team6" className="team_pop mfp-hide">
                                                    <div className="row m-0">
                                                        <div className="col-md-4 text-center">
                                                            <div className="team_img_wrap">
                                                                <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                                <div className="team_title">
                                                                    <h4>Kent Pierce</h4>
                                                                    <span>Invester</span>
                                                                </div>
                                                            </div>
                                                            <div className="social_single_team list_none mt-3">
                                                                <ul>
                                                                    <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-3">
                                                                <h5>About</h5>
                                                                <hr />
                                                                <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-3 col-sm-6 offset-sm-3">
                                            <div className="bg_light_dark radius_box team_box_s3 animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                <div className="text-center">
                                                    <img src="assets/images/dev.png" alt="placeholder9" />
                                                    <div className="team_social_s2 list_none">
                                                        <ul>
                                                            <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="team_info text-center">
                                                    <h4><a href="#team7" className="content-popup">Rose Morgen</a></h4>
                                                    <p>Invester</p>
                                                </div>
                                                <div id="team7" className="team_pop mfp-hide">
                                                    <div className="row m-0">
                                                        <div className="col-md-4 text-center">
                                                            <div className="team_img_wrap">
                                                                <img className="w-100" src="assets/images/placeholder3.jpg" alt="user_img-lg" />
                                                                <div className="team_title">
                                                                    <h4>Rose Morgen</h4>
                                                                    <span>Invester</span>
                                                                </div>
                                                            </div>
                                                            <div className="social_single_team list_none mt-3">
                                                                <ul>
                                                                    <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-3">
                                                                <h5>About</h5>
                                                                <hr />
                                                                <p>Founder of Venus Media Ltd and Owner of leading website for affiliates in the entertainment industry TakeBucks, he is a videographer, photographer and producer with a big number of successful entrepreneurships under his name over the last 18 years.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                    <section id="faq" className="bg_light_dark">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 offset-lg-2">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Got Questions? We’ve Got Sarcasm.</h4>
                                        <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">The Q&A list is still in progress, We were too busy to write out but, you probably already have the answers… or you’re at least pretending you do!</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row small_space">
                                <div className="col-lg-12 col-md-12">
                                    <div className="tab_content">
                                        <ul className="nav nav-pills tab_nav_s2 tab_color_white justify-content-center" id="pills-tab" role="tablist">
                                            <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                <a className="active" data-toggle="tab" href="#tab1">General</a>
                                            </li>
                                            <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                <a data-toggle="tab" href="#tab2">Pre-ICO & ICC </a>
                                            </li>
                                            <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.7s">
                                                <a data-toggle="tab" href="#tab3">ICO Tokens</a>
                                            </li>
                                            <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                <a data-toggle="tab" href="#tab4">Legal</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content half_tab">
                                            <div className="tab-pane fade show active" id="tab1" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div id="accordion1" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingOne">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is cryptocurrency?</a> </h6>
                                                                </div>
                                                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion1">
                                                                    <div className="card-body"> The best cryptocurrency to buy is one we are willing to hold onto even if it goes down. For example, I believe in Steem enough that I am willing to hold it even if it goes down 99% and would start buying more of it if the price dropped.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="headingTwo">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Which cryptocurrency is best to buy today?</a> </h6>
                                                                </div>
                                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion1">
                                                                    <div className="card-body"> The best cryptocurrency to buy is one we are willing to hold onto even if it goes down. For example, I believe in Steem enough that I am willing to hold it even if it goes down 99% and would start buying more of it if the price dropped.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="headingThree">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How about day trading crypto?</a> </h6>
                                                                </div>
                                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion1">
                                                                    <div className="card-body"> While profits are possible trading cryptocurrencies, so are losses. My first year involved me spending hundreds of hours trading Bitcoin with a result of losing over $5,000 with nothing to show for it. Simply trading digital currencies is very similar to gambling because no one really knows what is going to happen next although anyone can guess! While I was lucky to do nothing expect lose money when I started, the worst thing that can happen is to get lucky right away and get a big ego about what an amazing cryptocurrency trader we are. </div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="headingFour">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">When to sell a cryptocurrency?</a> </h6>
                                                                </div>
                                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion1">
                                                                    <div className="card-body"> Before Steem I was all in an another altcoin and really excited about it. When I first bought the price was low and made payments to me every week just for holding it. As I tried to participate in the community over the next several months, I was consistently met with a mix of excitement and hostility. When I began talking openly about this, the negative emotions won over in the community and in me. Originally I had invested and been happy to hold no matter what the price which quickly went up after I bought it. </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div id="accordion2" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingFive">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">How does one acquire bitcoins?</a> </h6>
                                                                </div>
                                                                <div id="collapseFive" className="collapse show" aria-labelledby="headingFive" data-parent="#accordion2">
                                                                    <div className="card-body"> The best cryptocurrency to buy is one we are willing to hold onto even if it goes down. For example, I believe in Steem enough that I am willing to hold it even if it goes down 99% and would start buying more of it if the price dropped.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="headingSix">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Can I make money with Bitcoin?</a> </h6>
                                                                </div>
                                                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion2">
                                                                    <div className="card-body"> You should never expect to get rich with Bitcoin or any emerging technology. It is always important to be wary of any thing that sounds too good to be true or disobeys basic </div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="headingSeven">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">What happens when bitcoins are lost?</a> </h6>
                                                                </div>
                                                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion2">
                                                                    <div className="card-body"> While profits are possible trading cryptocurrencies, so are losses. My first year involved me spending hundreds of hours trading Bitcoin with a result of losing over $5,000 with nothing to show for it. Simply trading digital currencies is very similar to gambling because no one really knows what is going to happen next although anyone can guess! While I was lucky to do nothing expect lose money when I started, the worst thing that can happen is to get lucky right away and get a big ego about what an amazing cryptocurrency trader we are. </div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="headingEight">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">Where is the invest my bitcoin?</a> </h6>
                                                                </div>
                                                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion2">
                                                                    <div className="card-body"> Before Steem I was all in an another altcoin and really excited about it. When I first bought the price was low and made payments to me every week just for holding it. As I tried to participate in the community over the next several months, I was consistently met with a mix of excitement and hostility. When I began talking openly about this, the negative emotions won over in the community and in me. Originally I had invested and been happy to hold no matter what the price which quickly went up after I bought it. </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="tab2" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div id="accordion3" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingNine">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseNine" aria-expanded="true" aria-controls="collapseNine">How does one acquire bitcoins?</a> </h6>
                                                                </div>
                                                                <div id="collapseNine" className="collapse show" aria-labelledby="headingNine" data-parent="#accordion3">
                                                                    <div className="card-body"> While it may be possible to find individuals who wish to sell bitcoins in exchange for a credit card or PayPal payment, most exchanges do not allow funding via these payment methods. This is due to cases where someone buys bitcoins with PayPal, and then reverses their half of the transaction. This is commonly referred to as a chargeback.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="headingTen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">Can I make money with Bitcoin?</a> </h6>
                                                                </div>
                                                                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion3">
                                                                    <div className="card-body"> You should never expect to get rich with Bitcoin or any emerging technology. It is always important to be wary of anything that sounds too good to be true or disobeys basic economic rules.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="headingEleven">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">What happens when bitcoins are lost?</a> </h6>
                                                                </div>
                                                                <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion3">
                                                                    <div className="card-body">When a user loses his wallet, it has the effect of removing money out of circulation. Lost bitcoins still remain in the block chain just like any other bitcoins. However, lost bitcoins remain dormant forever because there is no way for anybody to find the private key(s) that would allow them to be spent again. Because of the law of supply and demand, when fewer bitcoins are available, the ones that are left will be in higher demand and increase in value to compensate.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="headingTwelve">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">Who controls the Bitcoin network?</a> </h6>
                                                                </div>
                                                                <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion3">
                                                                    <div className="card-body">Nobody owns the Bitcoin network much like no one owns the technology behind email. Bitcoin is controlled by all Bitcoin users around the world. While developers are improving the software, they can't force a change in the Bitcoin protocol because all users are free to choose what software and version they use.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div id="accordion4" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingThirteen">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">How does one acquire bitcoins?</a> </h6>
                                                                </div>
                                                                <div id="collapseThirteen" className="collapse show" aria-labelledby="headingThirteen" data-parent="#accordion4">
                                                                    <div className="card-body"> While it may be possible to find individuals who wish to sell bitcoins in exchange for a credit card or PayPal payment, most exchanges do not allow funding via these payment methods. This is due to cases where someone buys bitcoins with PayPal, and then reverses their half of the transaction. This is commonly referred to as a chargeback.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="headingFourteen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">Can I make money with Bitcoin?</a> </h6>
                                                                </div>
                                                                <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordion4">
                                                                    <div className="card-body"> You should never expect to get rich with Bitcoin or any emerging technology. It is always important to be wary of anything that sounds too good to be true or disobeys basic economic rules.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="headingFifteen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">What happens when bitcoins are lost?</a> </h6>
                                                                </div>
                                                                <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordion4">
                                                                    <div className="card-body">When a user loses his wallet, it has the effect of removing money out of circulation. Lost bitcoins still remain in the block chain just like any other bitcoins. However, lost bitcoins remain dormant forever because there is no way for anybody to find the private key(s) that would allow them to be spent again. Because of the law of supply and demand, when fewer bitcoins are available, the ones that are left will be in higher demand and increase in value to compensate.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="headingSixteen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">Who controls the Bitcoin network?</a> </h6>
                                                                </div>
                                                                <div id="collapseSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordion4">
                                                                    <div className="card-body">Nobody owns the Bitcoin network much like no one owns the technology behind email. Bitcoin is controlled by all Bitcoin users around the world. While developers are improving the software, they can't force a change in the Bitcoin protocol because all users are free to choose what software and version they use.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="tab3" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div id="accordion5" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingSeventeen">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseSeventeen" aria-expanded="true" aria-controls="collapseSeventeen">How are bitcoins created?</a> </h6>
                                                                </div>
                                                                <div id="collapseSeventeen" className="collapse show" aria-labelledby="headingSeventeen" data-parent="#accordion5">
                                                                    <div className="card-body"> New bitcoins are generated by a competitive and decentralized process called "mining". This process involves that individuals are rewarded by the network for their services. Bitcoin miners are processing transactions and securing the network using specialized hardware and are collecting new bitcoins in exchange.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="headingEighteen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">Why do bitcoins have value?</a> </h6>
                                                                </div>
                                                                <div id="collapseEighteen" className="collapse" aria-labelledby="headingEighteen" data-parent="#accordion5">
                                                                    <div className="card-body">Bitcoins have value because they are useful as a form of money. Bitcoin has the characteristics of money (durability, portability, fungibility, scarcity, divisibility, and recognizability) based on the properties of mathematics rather than relying on physical properties (like gold and silver) or trust in central authorities (like fiat currencies). In short, Bitcoin is backed by mathematics. </div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="headingNineteen">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">What determines bitcoin's price?</a> </h6>
                                                                </div>
                                                                <div id="collapseNineteen" className="collapse" aria-labelledby="headingNineteen" data-parent="#accordion5">
                                                                    <div className="card-body"> The price of a bitcoin is determined by supply and demand. When demand for bitcoins increases, the price increases, and when demand falls, the price falls. There is only a limited number of bitcoins in circulation and new bitcoins are created at a predictable and decreasing rate</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="headingTwenty">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">Can bitcoins become worthless?</a> </h6>
                                                                </div>
                                                                <div id="collapseTwenty" className="collapse" aria-labelledby="headingTwenty" data-parent="#accordion5">
                                                                    <div className="card-body"> Yes. History is littered with currencies that failed and are no longer used, such as the German Mark during the Weimar Republic and, more recently, the Zimbabwean dollar.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div id="accordion6" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="headingNine">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapse21" aria-expanded="true" aria-controls="collapse21">How are bitcoins created?</a> </h6>
                                                                </div>
                                                                <div id="collapse21" className="collapse show" aria-labelledby="heading21" data-parent="#accordion6">
                                                                    <div className="card-body"> New bitcoins are generated by a competitive and decentralized process called "mining". This process involves that individuals are rewarded by the network for their services. Bitcoin miners are processing transactions and securing the network using specialized hardware and are collecting new bitcoins in exchange.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="heading22">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse22" aria-expanded="false" aria-controls="collapse22">Why do bitcoins have value?</a> </h6>
                                                                </div>
                                                                <div id="collapse22" className="collapse" aria-labelledby="heading22" data-parent="#accordion6">
                                                                    <div className="card-body">Bitcoins have value because they are useful as a form of money. Bitcoin has the characteristics of money (durability, portability, fungibility, scarcity, divisibility, and recognizability) based on the properties of mathematics rather than relying on physical properties (like gold and silver) or trust in central authorities (like fiat currencies). In short, Bitcoin is backed by mathematics. </div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="heading23">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse23" aria-expanded="false" aria-controls="collapse23">What determines bitcoin's price?</a> </h6>
                                                                </div>
                                                                <div id="collapse23" className="collapse" aria-labelledby="heading23" data-parent="#accordion6">
                                                                    <div className="card-body"> The price of a bitcoin is determined by supply and demand. When demand for bitcoins increases, the price increases, and when demand falls, the price falls. There is only a limited number of bitcoins in circulation and new bitcoins are created at a predictable and decreasing rate</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="heading24">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse24" aria-expanded="false" aria-controls="collapse24">Can bitcoins become worthless?</a> </h6>
                                                                </div>
                                                                <div id="collapse24" className="collapse" aria-labelledby="heading24" data-parent="#accordion6">
                                                                    <div className="card-body"> Yes. History is littered with currencies that failed and are no longer used, such as the German Mark during the Weimar Republic and, more recently, the Zimbabwean dollar.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="tab4" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div id="accordion7" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="heading25">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapse25" aria-expanded="true" aria-controls="collapse25">Is Bitcoin legal?</a> </h6>
                                                                </div>
                                                                <div id="collapse25" className="collapse show" aria-labelledby="heading25" data-parent="#accordion7">
                                                                    <div className="card-body">To the best of our knowledge, Bitcoin has not been made illegal by legislation in most jurisdictions. However, some jurisdictions (such as Argentina and Russia) severely restrict or ban foreign currencies. Other jurisdictions (such as Thailand) may limit the licensing of certain entities such as Bitcoin exchanges.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="heading26">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse26" aria-expanded="false" aria-controls="collapse26">Is Bitcoin useful for illegal activities?</a> </h6>
                                                                </div>
                                                                <div id="collapse26" className="collapse" aria-labelledby="heading26" data-parent="#accordion7">
                                                                    <div className="card-body">Bitcoin is money, and money has always been used both for legal and illegal purposes. Cash, credit cards and current banking systems widely surpass Bitcoin in terms of their use to finance crime. Bitcoin can bring significant innovation in payment systems and the benefits of such innovation are often considered to be far beyond their potential drawbacks.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="heading27">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse27" aria-expanded="false" aria-controls="collapse27">Can Bitcoin be regulated?</a> </h6>
                                                                </div>
                                                                <div id="collapse27" className="collapse" aria-labelledby="heading27" data-parent="#accordion7">
                                                                    <div className="card-body"> The Bitcoin protocol itself cannot be modified without the cooperation of nearly all its users, who choose what software they use. Attempting to assign special rights to a local authority in the rules of the global Bitcoin network is not a practical possibility.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="heading28">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse28" aria-expanded="false" aria-controls="collapse28">What about Bitcoin and taxes?</a> </h6>
                                                                </div>
                                                                <div id="collapse28" className="collapse" aria-labelledby="heading28" data-parent="#accordion7">
                                                                    <div className="card-body"> Bitcoin is not a fiat currency with legal tender status in any jurisdiction, but often tax liability accrues regardless of the medium used. There is a wide variety of legislation in many different jurisdictions which could cause income, sales, payroll, capital gains, or some other form of tax liability to arise with Bitcoin.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div id="accordion8" className="faq_content">
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                                <div className="card-header" id="heading29">
                                                                    <h6 className="mb-0"> <a data-toggle="collapse" href="#collapse29" aria-expanded="true" aria-controls="collapse29">Is Bitcoin legal?</a> </h6>
                                                                </div>
                                                                <div id="collapse29" className="collapse show" aria-labelledby="heading29" data-parent="#accordion8">
                                                                    <div className="card-body">To the best of our knowledge, Bitcoin has not been made illegal by legislation in most jurisdictions. However, some jurisdictions (such as Argentina and Russia) severely restrict or ban foreign currencies. Other jurisdictions (such as Thailand) may limit the licensing of certain entities such as Bitcoin exchanges.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                                <div className="card-header" id="heading30">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse30" aria-expanded="false" aria-controls="collapse30">Is Bitcoin useful for illegal activities?</a> </h6>
                                                                </div>
                                                                <div id="collapse30" className="collapse" aria-labelledby="heading30" data-parent="#accordion8">
                                                                    <div className="card-body">Bitcoin is money, and money has always been used both for legal and illegal purposes. Cash, credit cards and current banking systems widely surpass Bitcoin in terms of their use to finance crime. Bitcoin can bring significant innovation in payment systems and the benefits of such innovation are often considered to be far beyond their potential drawbacks.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                                <div className="card-header" id="heading31">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse31" aria-expanded="false" aria-controls="collapse31">Can Bitcoin be regulated?</a> </h6>
                                                                </div>
                                                                <div id="collapse31" className="collapse" aria-labelledby="heading31" data-parent="#accordion8">
                                                                    <div className="card-body"> The Bitcoin protocol itself cannot be modified without the cooperation of nearly all its users, who choose what software they use. Attempting to assign special rights to a local authority in the rules of the global Bitcoin network is not a practical possibility.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                                <div className="card-header" id="heading32">
                                                                    <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapse32" aria-expanded="false" aria-controls="collapse32">What about Bitcoin and taxes?</a> </h6>
                                                                </div>
                                                                <div id="collapse32" className="collapse" aria-labelledby="heading32" data-parent="#accordion8">
                                                                    <div className="card-body"> Bitcoin is not a fiat currency with legal tender status in any jurisdiction, but often tax liability accrues regardless of the medium used. There is a wide variety of legislation in many different jurisdictions which could cause income, sales, payroll, capital gains, or some other form of tax liability to arise with Bitcoin.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>

                    <div>
                        {/*       <WesternDuelGame />
 */}    </div>

                    <section id="contact" className="contact_section small_pt">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 offset-lg-2">
                                    <div className="title_default_light title_border text-center">
                                        <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Get In Touch!</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center small_space">
                                <div className="col-lg-4 col-md-6 offset-lg-2">
                                    <div className="bg_light_dark  contact_box_s2 animation" data-animation="fadeInLeft" data-animation-delay="0.1s">
                                        <div className="contact_title">
                                            <h5 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">Contact Us! We Love Hearing from Strangers!</h5>
                                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Our office? Oh, just nestled in a ‘beautiful’ building with a garden (if you like that sort of thing).</p>
                                        </div>
                                        {/* <ul className="list_none contact_info mt-margin">
                                            <li className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                <i className="ion-ios-location"></i>
                                                <div className="contact_detail"> <span>Address</span>
                                                    <p>22 International Division Via G.B. Pirelli</p>
                                                </div>
                                            </li>
                                            <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                <i className="ion-android-call"></i>
                                                <div className="contact_detail"> <span>Phone</span>
                                                    <p>+23 0123 4567</p>
                                                </div>
                                            </li>
                                            <li className="animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                <i className="ion-ios-email"></i>
                                                <div className="contact_detail"> <span>Email-id</span>
                                                    <p>Yourmail@gmail.com</p>
                                                </div>
                                            </li>
                                        </ul> */}
                                        <div className="contct_follow pt-2 pt-md-4">
                                            <span className="text-uppercase animation" data-animation="fadeInUp" data-animation-delay="0.2s">Follow Us</span>
                                            <ul className="list_none social_icon">
                                                <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                    <a href="https://x.com/trumpenguin" target="_blank" rel="noopener noreferrer" class="social-link">
                                                        <i className="fab fa-twitter"></i>
                                                        <span class="social-name">Follow the whispers on Twitter</span>
                                                    </a>
                                                </li>
                                                <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                    <a href="https://t.me/trumpenguin" target="_blank" rel="noopener noreferrer" class="social-link">
                                                        <i className="fab fa-telegram"></i>
                                                        <span class="social-name">Join the shadows on Telegram Group</span>
                                                    </a>
                                                </li>
                                                <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                    <a href="https://t.me/trumpenguin_announcements" target="_blank" rel="noopener noreferrer" class="social-link">
                                                        <i className="fab fa-telegram"></i>
                                                        <span class="social-name">Unveil the secrets on Telegram Announcements</span>
                                                    </a>
                                                </li>
{/*                                                 <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                    <a href="https://discord.gg/DXShQbBa" target="_blank" rel="noopener noreferrer" class="social-link">
                                                        <i className="fab fa-discord"></i>
                                                        <span class="social-name">Enter the realm of Discord and join the darkness</span>
                                                    </a>
                                                </li>
                                                <li className="animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                                    <a href="https://www.youtube.com/@Trumpenguin_Solana" target="_blank" rel="noopener noreferrer" class="social-link">
                                                        <i className="fab fa-youtube"></i>
                                                        <span class="social-name">Unleash the power on YouTube</span>
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <div className="pt-4 pt-md-0 animation" data-animation="fadeInRight" data-animation-delay="0.1s">
                                        <form method="post" name="enq" className="field_form">
                                            <div className="row">
                                                <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                    <input type="text" required="required" placeholder="Enter Name *" id="first-name" className="form-control" name="name" />
                                                </div>
                                                <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                    <input type="email" required="required" placeholder="Enter Email *" id="email" className="form-control" name="email" />
                                                </div>
                                                <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                    <input type="text" required="required" placeholder="Enter Subject" id="subject" className="form-control" name="subject" />
                                                </div>
                                                <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                    <textarea required="required" placeholder="Message *" id="description" className="form-control" name="message" rows="2"></textarea>
                                                </div>
                                                <div className="col-md-12 text-center animation" data-animation="fadeInUp" data-animation-delay="1.2s">
                                                    <button
                                                        type="submit"
                                                        title="Submit Your Message!"
                                                        className="btn btn-default btn-radius btn-block"
                                                        id="submitButton"
                                                        name="submit"
                                                        value="Submit"
                                                        onClick={showSarcasticMessage}
                                                    >
                                                        Submit <i className="ion-ios-arrow-thin-right"></i>
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div id="alert-msg" className="alert-msg text-center"></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer>
                        <div className="bottom_footer">
                            <div className="container">
                            <div>
                            <NoCopyPasteMessage />
                                    </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="copyright">Copyright &copy; 2024 All Rights Reserved</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="list_none footer_menu">
                                            <li>
                                                <a href="/" onClick={(e) => showPopup(e, 'Privacy Policy')}>
                                                    Privacy Policy
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/" onClick={(e) => showPopup(e, 'Terms & Conditions')}>
                                                    Terms & Conditions
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div >
            </div>
        </>
    );
}

export default App;