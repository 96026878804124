import React, { useEffect, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import '@solana/wallet-adapter-react-ui/styles.css';

const WalletContext = ({ children }) => {
    const network = WalletAdapterNetwork.Mainnet;

    // Use the custom endpoint or fallback to the default Solana Mainnet
    const endpoint = useMemo(
        () => 'https://radial-boldest-mansion.solana-mainnet.quiknode.pro/7bbb1ede66682f214339809b363d25ca0e8729f4/' || clusterApiUrl(network),
        [network]
    );

    // No need to manually specify wallets, as standard wallets like Phantom auto-register
    const wallets = useMemo(() => [], []); // Keep this empty for standard wallets

    useEffect(() => {
        const connection = new Connection(endpoint);
        (async () => {
            const slot = await connection.getSlot();
            console.log("Current slot:", slot); // Logs the current slot to confirm connection
        })();
    }, [endpoint]);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={false}>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletContext;
