import React from 'react';
import guideImage from './assets/images/liquidity_pool_farm.png';

const LiquidityFarmInfo = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Join the revolution by adding liquidity, staking, and farming <strong>$TPG</strong> tokens!</h1>
      {/* Use the imported image here */}
      <img
        src={guideImage}
        alt="Dark Art Guide"
        style={{ width: '100%', marginBottom: '10px', borderRadius: '10px' }}
      />
      <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.5', marginBottom: '10px' }}>
        To partake in the dark art of liquidity farming and claim your <strong>$TPG</strong> rewards, follow these twisted steps:
      </p>
      <ol style={styles.text}>
        <li>
          <strong>
            <a
              href="https://raydium.io/liquidity/increase/?mode=add&pool_id=6v8RPeJQHYiT96HVTYynD8GhWrKLuz63U5jSZm2SrTv1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>Add Liquidity to RAYDIUM:
            </a>
          </strong> First, unleash the power by adding liquidity to the <strong>$TPG</strong> and <strong>SOL</strong> pair (or any other pairs that dare to be supported). By doing so, you shall control the flow of wealth and begin claiming your rewards from the shadows.
        </li>
        <li><strong>
          <a
            href="https://raydium.io/liquidity/increase/?mode=stake&pool_id=6v8RPeJQHYiT96HVTYynD8GhWrKLuz63U5jSZm2SrTv1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>Stake Your Liquidity:
          </a></strong> Once you've summoned liquidity, you may stake your <strong>LP tokens</strong> in the dark depths of the <strong>RAYDIUM Farms</strong>, where you will harvest additional <strong>$TPG</strong> tokens as rewards—fuel for your growing empire.</li>
        <li><strong>
          <a
            href="https://raydium.io/farms/edit/?farmId=HgwNnPv42Pe8cfKQd7w2PJ5AF76DWfn4VYVGNzyV1Mmj"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>Farm Up to 3,000,000 $TPG:
          </a></strong> You can stake and farm up to <strong>3,000,000 $TPG</strong> tokens in the shadows of RAYDIUM, where your rewards await. The more liquidity you pour into the abyss, the greater the spoils that will be yours to claim. <a
            href="https://solscan.io/token/2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>Farm CA
          </a>
        </li>
      </ol>
      <p style={styles.text}>
        For a sinister and detailed step-by-step guide on how to add liquidity, stake, and farm on RAYDIUM, seek out the full instructions in the <a href="https://docs.raydium.io/raydium/liquidity-providers/providing-concentrated-liquidity-clmm" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}>RAYDIUM How To Guide
        </a> Embrace the darkness and unlock the secrets of wealth.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#0c0e27',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '1000px',
    margin: 'auto',
    color: 'white',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  text: {
    fontSize: '16px',
    lineHeight: '1.5',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

export default LiquidityFarmInfo;
