import './App.css';
import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";

const HomeSection = () => {
  const smartContract = "2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1";
  const [copySuccess, setCopySuccess] = useState(false);
  const [reveal, setReveal] = useState(false);

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(smartContract);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = smartContract;
        textArea.style.position = "fixed";
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Failed to copy smart contract:", err);
    }
  };

  return (
    <section
      id="home_section"
      style={{
        position: "relative",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0",
        padding: "0",
      }}
    >
      {/* Video Element */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",
          height: "auto",
          zIndex: "1",
          borderRadius: "10px",
        }}
      >
        <source src="/assets/video/Trumpenguin_MCCA.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div
        className="container"
        style={{
          position: "absolute",
          top: "15%",
          textAlign: "center",
          color: "#fff",
          zIndex: "2"
        }}
      >
        {/* Reveal/Hide Button */}
        {!reveal ? (
          <Button
            onClick={() => setReveal(true)}
            className="reveal-button" 
          >
            Unveil the secret SC
          </Button>
        ) : (
          <Button
            onClick={() => setReveal(false)}
            className="reveal-button" 
          >
            Hide the secret
          </Button>
        )}
        {/* Smart Contract Section */}
        <div
          className="container smart-contract-container pt-2"
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          <Tooltip title="Click to copy the smart contract">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                height: "50px",
                textAlign: "center",
              }}
            >
              <h1
                className="smart-contract-text"
                style={{
                  background: reveal
                    ? "linear-gradient(135deg, #BE60B0, #EAAC25)"
                    : "transparent", 
                  color: reveal ? "#FFFFFF" : "transparent", 
                }}
              >
                {smartContract}
              </h1>
            </div>
          </Tooltip>
          {reveal && (
            <Button
              icon={copySuccess ? <CheckOutlined /> : <CopyOutlined />}
              type="primary"
              shape="circle"
              className="copy-button"
              onClick={handleCopy}
              style={{
                fontSize: "18px",
                marginTop: "20px",
                padding: "4px",
                background: "linear-gradient(135deg, #BE60B0, #EAAC25)",
                border: "none",
                outline: "none",
                boxShadow: "0 0 0 2px #BE60B0",
              }}
            />
          )}
          {copySuccess && (
            <p
              className="copy-success-message text-success"
              style={{ textAlign: "center" }}
            >
              Copied to clipboard!
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
