import React from 'react';

const CryptoLinks = () => {
  const links = [
    { href: "https://ape.pro/solana/2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1", text: "ApePro" },
    { href: "https://solanahunters.com/coin/2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1", text: "SolanaHunters" },
    { href: "https://gemsradar.com/coins/trumpenguin", text: "GemsRadar" },
    { href: "https://top100token.com/address/2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1", text: "top100token" },
    { href: "https://coinhunt.cc/coin/674306154df5ec695e1d07a3", text: "Coinhunt" },
    { href: "https://fomospider.com/coin/TRUMPENGUIN", text: "FomoSpider" },
    { href: "https://coinmooner.com/coin/trumpenguin-tpg", text: "coinmooner" },
    { href: "https://coincatapult.com/coin/trumpenguin-tpg", text: "CoinCatapult" },
    { href: "https://coinmoonhunt.com/coin/TRUMPENGUIN", text: "CoinMoonHunt" },
    { href: "https://coinscope.co/coin/1-tpg", text: "Coinscope" },
    { href: "https://coinlists.net/coin/3156", text: "CoinLists" },
    { href: "https://coinbazooka.com/coin/trumpenguin", text: "CoinBazooka" },
    { href: "https://www.freshcoins.io/coins/trumpenguin", text: "freshcoins" },
    { href: "https://www.mintme.com/token/TRUMPENGUIN", text: "MintMe.com" }
  ];

  const linkStyle = {
    color: "#fff",
    textDecoration: "none",
    fontSize: "16px",
    fontFamily: "MedievalSharp, sans-serif",
    fontWeight: "bold",
    textShadow: `
      2px 2px 5px rgba(0, 0, 0, 0.7),
      -2px -2px 5px rgba(255, 0, 0, 0.8)
    `,
    letterSpacing: "1px",
    transition: "all 0.3s ease",
    display: "block",
  };

  const handleMouseEnter = (e) => {
    e.target.style.textShadow = `
      2px 2px 10px rgba(0, 0, 0, 0.7),
      -2px -2px 10px rgba(255, 0, 0, 1)
    `;
    e.target.style.transform = "scale(1.1)";
  };

  const handleMouseLeave = (e) => {
    e.target.style.textShadow = `
      2px 2px 5px rgba(0, 0, 0, 0.7),
      -2px -2px 5px rgba(255, 0, 0, 0.8)
    `;
    e.target.style.transform = "scale(1)";
  };

  return (
    <div
      style={{
        width: "100%",
        height: "120px",
        overflowY: "scroll",
        padding: "10px",
        background: "#000",
        borderRadius: "50px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: "10px",
        }}
      >
        {links.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
};

export default CryptoLinks;