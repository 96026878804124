import React from 'react';

import dextoolsLogo from './assets/images/dextools.png';
import dexscreenerLogo from './assets/images/dexscreener.png';
import birdeyeLogo from './assets/images/birdeye.png';
import geckoterminalLogo from './assets/images/geckoterminal.png';

const CryptoLinks = () => {
  const links = [
    { href: "https://www.dextools.io/app/en/token/trumpenguin", imgSrc: dextoolsLogo, alt: "DEXTOOLS", name: "dextools" },
    { href: "https://dexscreener.com/solana/6v8rpejqhyit96hvtyynd8ghwrkluz63u5jszm2srtv1", imgSrc: dexscreenerLogo, alt: "DEXSCREENER", name: "dexscreener" },
    { href: "https://www.birdeye.so/token/2eK5WyE5uVrn1SRmNp9SsxQovzrs1PhrN33breBTgkf1?chain=solana", imgSrc: birdeyeLogo, alt: "BIRDEYE", name: "birdeye" },
    { href: "https://www.geckoterminal.com/solana/pools/6v8RPeJQHYiT96HVTYynD8GhWrKLuz63U5jSZm2SrTv1", imgSrc: geckoterminalLogo, alt: "GECKOTERMINAL", name: "geckoterminal" }
  ];

  const linkStyle = {
    display: "flex",
    flexDirection: "column", 
    alignItems: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    textAlign: "center", 
  };

  const handleMouseEnter = (e) => {
    e.target.style.transform = "scale(1.1)";
  };

  const handleMouseLeave = (e) => {
    e.target.style.transform = "scale(1)";
  };

  return (
    <div
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(50px, 1fr))",
          gap: "10px",
        }}
      >
        {links.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={link.imgSrc}
              alt={link.alt}
              style={{
                width: "40px",  // Set a fixed width
                height: "40px", // Set a fixed height to ensure uniformity
                objectFit: "contain", // Ensures the aspect ratio is maintained
                marginBottom: "5px", // Space between logo and name
              }}
            />
            <div
              style={{
                fontSize: "12px", // Small text size
                color: "#fff",
                textTransform: "lowercase", // Convert text to lowercase
                fontFamily: "Arial, sans-serif", // Choose a simple font
              }}
            >
              {link.name}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default CryptoLinks;
